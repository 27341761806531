// trenutno merkator cijene

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ORDERS_COLLECTION, serverTimestamp, USERS_COLLECTION } from '../../firebase'
import { orderAction } from '../../redux/actions'
import { _idOrderSelector, doneTimeOrderSelector, donePersonOrderSelector, enterPersonOrderSelector, enterTimeOrderSelector, exteriorVehicleWashingPaidOrderSelector, exteriorVehicleWashingOrderSelector, internalVehicleWashingOrderSelector, methodOfPaymentOrderSelector, noteOrderSelector, vehicleColorOrderSelector, vehiclePlatesOrderSelector, vehicleTypeOrderSelector, } from '../../redux/selectors'
import { v4 as uuidv4 } from 'uuid'

import './Enter.style.css'

import { ReactComponent as IconCancel } from './assets/icon-cancel.svg'

const Enter = () => {

    const { id } = useParams()

    const dispatch = useDispatch()

    const history = useHistory()

    const [users, setUsers] = useState([])
    const [usersLoading, setUsersLoading] = useState(false)

    const _idOrder = useSelector(_idOrderSelector)
    const donePersonOrder = useSelector(donePersonOrderSelector)
    const doneTimeOrder = useSelector(doneTimeOrderSelector)
    const enterPersonOrder = useSelector(enterPersonOrderSelector)
    const enterTimeOrder = useSelector(enterTimeOrderSelector)
    const exteriorVehicleWashingPaidOrder = useSelector(exteriorVehicleWashingPaidOrderSelector)
    const exteriorVehicleWashingOrder = useSelector(exteriorVehicleWashingOrderSelector)
    const internalVehicleWashingOrder = useSelector(internalVehicleWashingOrderSelector)
    const methodOfPaymentOrder = useSelector(methodOfPaymentOrderSelector)
    const noteOrder = useSelector(noteOrderSelector)
    const vehicleColorOrder = useSelector(vehicleColorOrderSelector)
    const vehiclePlatesOrder = useSelector(vehiclePlatesOrderSelector)
    const vehicleTypeOrder = useSelector(vehicleTypeOrderSelector)

    useEffect(() => {

        const getUsers = async () => {
            setUsersLoading(true)
            const snapshot = await USERS_COLLECTION.get()
            const orders = []
            snapshot.forEach(doc => {
                orders.push(doc.data())
            })
            setUsers(orders)
            setUsersLoading(false)
        }

        getUsers()

        if (id) {

            const getOrders = async () => {

                const snapshot = await ORDERS_COLLECTION.where('_id', '==', id).get()

                snapshot.forEach(doc => {

                    const data = doc.data()

                    dispatch(orderAction({
                        enterPerson: data.enterPerson,
                        enterTime: data.enterTime,
                        vehiclePlates: data.vehiclePlates,
                    }))

                })
            }

            getOrders()
        }

        document.body.style = 'background: #e5e5ea'

    }, [])

    const handleAdd = async (e) => {

        e.preventDefault()

        const uuid = uuidv4()

        await ORDERS_COLLECTION.doc(uuid).set({
            _id: uuid,

            amountTotal:

                // ? Vanjsko

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'BRZI' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'BRZI' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'PREMIUM' ? 18 : 0) +

                // ? Unutrasnje

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'BRZI' ? 2 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM PLUS' ? 10 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'BRZI' ? 6 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'PREMIUM' ? 6 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0)

            ,

            amountExteriorVehicleWashing:

                // ? Vanjsko

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'BRZI' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'BRZI' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'PREMIUM' ? 18 : 0)

            ,

            amountInternalVehicleWashing:

                // ? Unutrasnje

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'BRZI' ? 2 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM PLUS' ? 10 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'BRZI' ? 6 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'PREMIUM' ? 6 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0)
            ,

            donePerson: exteriorVehicleWashingPaidOrder === 'DA' && internalVehicleWashingOrder === 'NE' ? enterPersonOrder : donePersonOrder,
            doneTime: exteriorVehicleWashingPaidOrder === 'DA' && internalVehicleWashingOrder === 'NE' ? serverTimestamp.now() : doneTimeOrder,
            enterPerson: enterPersonOrder,
            enterTime: serverTimestamp.now(),
            exteriorVehicleWashingPaid: exteriorVehicleWashingPaidOrder,
            exteriorVehicleWashing: exteriorVehicleWashingOrder,
            internalVehicleWashing: internalVehicleWashingOrder,
            methodOfPayment: methodOfPaymentOrder,
            note: noteOrder,
            vehicleColor: vehicleColorOrder,
            vehiclePlates: vehiclePlatesOrder,
            vehicleType: vehicleTypeOrder
        })
            .then(() => {
                history.push('/')
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    const handleEdit = async (e) => {

        e.preventDefault()

        await ORDERS_COLLECTION.doc(id).set({
            _id: _idOrder,

            amountTotal:

                // ? Vanjsko

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'BRZI' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'BRZI' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'PREMIUM' ? 18 : 0) +

                // ? Unutrasnje

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'BRZI' ? 2 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM PLUS' ? 10 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'BRZI' ? 6 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'PREMIUM' ? 6 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0)

            ,

            amountExteriorVehicleWashing:

                // ? Vanjsko

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'BRZI' ? 5 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KOMBI' && exteriorVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'KOMERCIJALNO' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'BRZI' ? 18 : 0) +
                (vehicleTypeOrder === 'KAMION' && exteriorVehicleWashingOrder === 'PREMIUM' ? 18 : 0)

            ,

            amountInternalVehicleWashing:

                // ? Unutrasnje

                // AUTO/SUV/VAN
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'BRZI' ? 2 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0) +
                (vehicleTypeOrder === 'AUTO/SUV/VAN' && internalVehicleWashingOrder === 'PREMIUM PLUS' ? 10 : 0) +

                // KOMBI
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'BRZI' ? 6 : 0) +
                (vehicleTypeOrder === 'KOMBI' && internalVehicleWashingOrder === 'PREMIUM' ? 6 : 0) +

                // KAMION
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'NE' ? 0 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'BRZI' ? 8 : 0) +
                (vehicleTypeOrder === 'KAMION' && internalVehicleWashingOrder === 'PREMIUM' ? 8 : 0)
            ,

            donePerson: exteriorVehicleWashingPaidOrder === 'DA' && internalVehicleWashingOrder === 'NE' ? enterPersonOrder : donePersonOrder,
            doneTime: exteriorVehicleWashingPaidOrder === 'DA' && internalVehicleWashingOrder === 'NE' ? serverTimestamp.now() : doneTimeOrder,
            enterPerson: enterPersonOrder,
            enterTime: enterTimeOrder,
            exteriorVehicleWashingPaid: exteriorVehicleWashingPaidOrder,
            exteriorVehicleWashing: exteriorVehicleWashingOrder,
            internalVehicleWashing: internalVehicleWashingOrder,
            methodOfPayment: methodOfPaymentOrder,
            note: noteOrder,
            vehicleColor: vehicleColorOrder,
            vehiclePlates: vehiclePlatesOrder,
            vehicleType: vehicleTypeOrder
        })
            .then(() => {
                history.push('/')
            })
            .catch((error) => {
                alert(error.message)
            })
    }

    return (
        <>
            <div className="enter">
                <IconCancel
                    className='enter__icon-cancel'
                    onClick={() => history.goBack()}
                />
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className="enter__title">
                                Dodaj vozilo
                            </div>
                            <div className="enter__vehicle-plates">
                                {vehiclePlatesOrder ? vehiclePlatesOrder : '-'}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {
                            !usersLoading
                                ?
                                users.map(user => {
                                    return (
                                        <Col key={user._id} xs={6} sm={6} md={6} lg={6}>
                                            <button
                                                className={`enter__user-button ${enterPersonOrder === user.user && "enter__user-button--active"}`}
                                                onClick={enterPersonOrder === user.user ? () => dispatch(orderAction({ enterPerson: '' })) : () => dispatch(orderAction({ enterPerson: user.user }))}
                                            >
                                                {user.user}
                                            </button>
                                        </Col>
                                    )
                                })
                                :
                                null
                        }
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {
                                enterPersonOrder === ''
                                    ?
                                    <button
                                        className='enter__submit-button'
                                        onClick={() => history.goBack()}
                                    >
                                        Odustani
                                    </button>
                                    :
                                    <button
                                        className='enter__submit-button'
                                        onClick={id ? handleEdit : handleAdd}
                                    >
                                        Sačuvaj
                                    </button>

                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Enter